import { useState, useEffect } from 'react';
import { Observable } from 'rxjs/Observable';
import { useFlags } from 'launchdarkly-react-client-sdk';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/empty';

import { useAjaxResponse } from 'app/hooks/ajax/use-ajax-response.hook';
import { AvailableLoadDetail, AvailableLoadError, AvailableLoadNotFound } from 'shared/models/loads/available-load-detail.model';
import { AvailableLoadsRepository } from 'app/repositories/available-loads.repository';
import { ShipmentsRepository } from 'app/repositories/shipments.repository';
import { useRepository } from 'app/hooks/ajax/use-repository.hook';

interface UseAvailableLoadDetailHook {
  (loadId: number, loaderName?: string): [AvailableLoadDetail, AvailableLoadNotFound | AvailableLoadError, (silent?: boolean) => void];
}

export const useAvailableLoadDetails: UseAvailableLoadDetailHook = (loadId, loaderName = null) => {
  const [error, setError] = useState<AvailableLoadNotFound | AvailableLoadError>(null);
  const repoOld = useRepository(AvailableLoadsRepository);
  const repo = useRepository(ShipmentsRepository);
  const { shipmentService } = useFlags();

  useEffect(() => {
    setError(e => null);
  }, [loadId]);

  const [load, refresh] = useAjaxResponse(
    () => {
      if (!loadId) return Observable.empty();

      /* NEW shipment service*/
      if (shipmentService) {
        return repo.getLoad(loadId).catch(err => {
          const errorResult = err.status === 404 ? new AvailableLoadNotFound(loadId) : new AvailableLoadError(err, loadId);
          setError(errorResult);
          return Observable.empty();
        });
      }
      /* End NEW shipment service*/

      /* OLD shipment service*/
      return repoOld.getLoad(loadId).catch(err => {
        const errorResult = err.status === 404 ? new AvailableLoadNotFound(loadId) : new AvailableLoadError(err, loadId);
        setError(errorResult);
        return Observable.empty();
      });
      /* End OLD shipment service*/
    },
    [loadId], { loaderName }
  );

  return [load, error, refresh];
};
